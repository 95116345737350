import React from "react";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { MdOutlinePayments } from "react-icons/md";
import { CiCalendarDate } from "react-icons/ci";

const CampaignPaymentInfo = ({ campaignData }) => {
  const convertDate = (dateStr) => {
    const date = new Date(dateStr);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  };
  return (
    <div className="row campaign_budget">
      <div className="col-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="icon">
                <GiReceiveMoney />
              </div>
              <p className="light_text mt-3">Campaign Budget</p>
              <p className="card_big_text">
                ₹ {campaignData?.campaignBudget?.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="icon">
                <GiTakeMyMoney />
              </div>
              <p className="light_text mt-3">Influencer Budget</p>
              <p className="card_big_text">
                {campaignData?.influencersBudget?.toLowerCase() === "none"
                  ? campaignData?.influencersBudget
                  : "₹ " + campaignData?.influencersBudget?.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="card">
              <div className="icon">
                <GiTakeMyMoney />
              </div>
              <p className="light_text mt-3">Payment Terms</p>
              <p className="card_big_text">{campaignData?.paymentTerms}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="row">
          <div className="col-md-6">
            <div className="card">
              <div className="icon">
                <MdOutlinePayments />
              </div>
              <p className="light_text mt-3">Agency Fees</p>
              <p className="card_big_text">
                ₹ {campaignData?.agencyFees?.toLocaleString()}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="icon">
                <CiCalendarDate />
              </div>
              <p className="light_text mt-3">Advance Payment Date</p>
              <p className="card_big_text">
                {convertDate(campaignData?.advancePaymentDate)}
              </p>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="card">
              <div className="icon">
                <GiTakeMyMoney />
              </div>
              <p className="light_text mt-3">Advance Payment Amount</p>
              <p className="card_big_text">
                {campaignData?.advancePaymentAmount}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <div className="card h-100">
          <div className="icon">
            <MdOutlinePayments />
          </div>
          <p className="light_text mt-5 ellipses">Campaign Start Date</p>
          <p className="card_big_text">
            {convertDate(campaignData?.campaignStartDate)}
          </p>
          <p className="light_text mt-3 ellipses">Campaign End Date</p>
          <p className="card_big_text">
            {convertDate(campaignData?.campaignEndDate)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CampaignPaymentInfo;
