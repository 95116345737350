import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import AddData from "./components/AddData";
import AddDataHome from "./components/AddDataHome";
import InfluencerProfile from "./components/influencer-profile/InfluencerProfile";
import Dashboard from "./components/dashboard/Dashboard";
import Influencers from "./components/influencers/Influencers";
import Settings from "./components/settings/Settings";
import Password from "./components/settings/Password";
import BackupRecovery from "./components/settings/BackupRecovery";
import BulkUploadData from "./components/BulkUploadData";
import UsersList from "./components/create-user/UsersList";
import CreateUser from "./components/create-user/CreateUser";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "./utils/userSlice";
import UserAccess from "./components/create-user/UserAccess";
import useGetTotalInfluencers from "./utils/useGetTotalInfluencers";
import { addInfluencers, setLoading } from "./utils/allInfluencersSlice";
import { jwtDecode } from "jwt-decode";
import Campaigns from "./components/campaigns/Campaigns";
import ViewAllCampaign from "./components/campaigns/ViewAllCampaign";
import TrashedItems from "./components/settings/TrashedItems";
import PageNotFound from "./components/PageNotFound";
import CampaignDetails from "./components/campaigns/CampaignDetails";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("token")
  );

  useEffect(() => {
    const token = user?.token;
    setIsAuthenticated(!!token);
  }, []);

  const [isLoading, allInfluencers] = useGetTotalInfluencers(isAuthenticated);
  console.log(allInfluencers);

  useEffect(() => {
    if (isLoading !== undefined) {
      dispatch(setLoading(isLoading));
    }

    if (allInfluencers) {
      dispatch(addInfluencers(allInfluencers));
    }
  }, [isLoading, allInfluencers, dispatch]);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const decodedToken = jwtDecode(token);
      if (Date.now() >= decodedToken.exp * 1000) {
        handleLogout();
      } else {
        const expiryTime = decodedToken.exp * 1000 - Date.now();
        setTimeout(() => {
          handleLogout();
        }, expiryTime);
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(removeUser());
    setIsAuthenticated(false);
  };

  const { userData: { permissions: { addData, editData } = {} } = {} } =
    user || {};

  const router = createBrowserRouter([
    {
      path: "/",
      element: isAuthenticated ? (
        user?.userData?.permissions?.showFullDashboard ? (
          <Dashboard onLogout={handleLogout} />
        ) : (
          <Navigate to="/influencers" />
        )
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/influencers",
      element: isAuthenticated ? (
        <Influencers onLogout={handleLogout} />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/login",
      element: !isAuthenticated ? (
        <Login onLogin={handleLogin} />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/add-data",
      element: isAuthenticated ? (
        user?.userData?.isAdmin ||
        user?.userData?.permissions?.uploadFile ||
        user?.userData?.permissions?.addData ? (
          <AddDataHome onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/add-data/manually",
      element: isAuthenticated ? (
        addData ? (
          <AddData onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/add-data/bulk-upload",
      element: isAuthenticated ? (
        user?.userData?.isAdmin || user?.userData?.permissions?.uploadFile ? (
          <BulkUploadData onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/influencer-profile/:id",
      element: isAuthenticated ? <InfluencerProfile /> : <Navigate to="/" />,
    },
    {
      path: "/edit/:id",
      element: isAuthenticated ? (
        editData ? (
          <AddData onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/settings/basic-informations",
      element: isAuthenticated ? (
        user?.userData?.permissions?.settings || user?.userData?.isAdmin ? (
          <Settings onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/settings/password",
      element: isAuthenticated ? (
        user?.userData?.permissions?.settings || user?.userData?.isAdmin ? (
          <Password onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/settings/backup-and-recovery",
      element: isAuthenticated ? (
        user?.userData?.permissions?.settings || user?.userData?.isAdmin ? (
          <BackupRecovery onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/settings/trash",
      element: isAuthenticated ? (
        user?.userData?.permissions?.settings || user?.userData?.isAdmin ? (
          <TrashedItems onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/users",
      element: isAuthenticated ? (
        user.userData.isAdmin ? (
          <UsersList onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/users/create-user",
      element: isAuthenticated ? (
        user.userData.isAdmin ? (
          <CreateUser onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/users/edit-user/:id",
      element: isAuthenticated ? (
        user.userData.isAdmin ? (
          <CreateUser onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/users/user-access/:id",
      element: isAuthenticated ? (
        user.userData.isAdmin ? (
          <UserAccess onLogout={handleLogout} />
        ) : (
          <PageNotFound />
        )
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/campaign/create-campaign",
      element: isAuthenticated ? (
        <Campaigns onLogout={handleLogout} />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/campaign/all-campaigns",
      element: isAuthenticated ? (
        <ViewAllCampaign onLogout={handleLogout} />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/campaign/edit-campaign/:id",
      element: isAuthenticated ? (
        <Campaigns onLogout={handleLogout} />
      ) : (
        <Navigate to="/" />
      ),
    },
    {
      path: "/campaign/campaign-details/:id",
      element: isAuthenticated ? (
        <CampaignDetails onLogout={handleLogout} />
      ) : (
        <Navigate to="/" />
      ),
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
