import * as React from "react";
import { BarChart } from "@mui/x-charts";
import { useSelector } from "react-redux";

function LineGraph() {
  const { topCategories } = useSelector(
    (store) => store?.influencers?.influencersList
  ) || { topCategories: {} };

  const sortedCategories = Object.entries(topCategories);
  const categories = sortedCategories?.map(
    ([category]) => category.charAt(0).toUpperCase() + category.slice(1)
  );
  const data = sortedCategories?.map(([, count]) => count);

  return (
    <div className="linegraph_main">
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: categories,
          },
        ]}
        series={[
          {
            data: data,
            color: "rgb(128, 196, 233)",
          },
        ]}
        width={600}
        height={350}
      />
    </div>
  );
}

export default LineGraph;
