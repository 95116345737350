import React, { useEffect, useState } from "react";
import SettingSidebar from "./SettingSidebar";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import { Paper, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MdDelete, MdRestore } from "react-icons/md";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { toast, ToastContainer } from "react-toastify";
import TableLoader from "../influencers/TableLoader";
import ProfilePic from "../ProfilePic";

const TrashedItems = ({ onLogout }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [trashItems, setTrashItems] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");

  const getTrashItems = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${baseUrl}/trash?page=${paginationModel.page + 1}&limit=${
          paginationModel.pageSize
        }`,
        {
          headers: { "auth-token": token },
        }
      );
      setTrashItems(response?.data?.trashItems);
      setTotal(response?.data?.total);
    } catch (error) {
      console.error("Error fetching trash items:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTrashItems();
  }, [paginationModel]);

  const columns = [
    { field: "name", headerName: "Name", width: 130 },
    { field: "deletedBy", headerName: "Deleted by", width: 130 },
    {
      field: "time",
      headerName: "Time",
      width: 130,
    },
    {
      field: "date",
      headerName: "Date",
      width: 160,
    },
    {
      field: "takeActions",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => (
        <div className="actions">
          <Tooltip title="Restore" placement="top">
            <button
              className="btn btn_access p-0"
              onClick={() => restoreItem(params?.row?.id)}
            >
              <MdRestore />
            </button>
          </Tooltip>
          <Tooltip title="Delete Permanently" placement="top">
            <button
              className="btn btn_edit p-0 ms-3"
              onClick={() => handleDelete([params?.row?.id])}
            >
              <MdDelete />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows = trashItems?.map((item) => ({
    id: item?._id,
    deletedBy: item?.deletedBy,
    name:
      item?.itemData?.name ||
      item?.itemData?.Name ||
      item?.itemData?.campaignName ||
      item?.itemData?.fileName,
    time: item?.time,
    date: item?.date,
  }));

  const restoreItem = async (id) => {
    try {
      const response = await axios.post(`${baseUrl}/trash/restore/${id}`, {
        headers: { "auth-token": token },
      });
      toast.success(response.data.message);
      setTrashItems(trashItems.filter((item) => item._id !== id));
    } catch (error) {
      console.error("Error restoring item:", error);
      toast.error("Error restoring item");
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedItems(newSelection);
  };

  const handleDeleteSelected = () => {
    handleDelete(selectedItems);
  };

  const notifySuccess = (message) =>
    toast.success(message || "Operation successful!");
  const notifyError = (error) => toast.error(error);

  const handleDelete = async (idsToDelete) => {
    try {
      const res = await axios.delete(`${baseUrl}/trash/permanentlyDelete`, {
        data: { ids: idsToDelete },
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });
      notifySuccess(res.data.message);

      setTrashItems((prev) =>
        prev?.filter((trashItem) => !idsToDelete?.includes(trashItem?._id))
      );
      setSelectedItems([]);
    } catch (error) {
      console.error("Error deleting items:", error);
      notifyError("Error deleting items");
    }
  };

  return (
    <div className="home_main">
      <ScrollToTop />
      <ToastContainer />
      <div className="wrapper">
        <Sidebar />
        <div className="main-panel" style={{ backgroundColor: "#f4f8fb" }}>
          <div className="dashboard_main">
            <div className="infl_header_top">
              <div className="dashboard_header infl_head">
                <h4>Settings</h4>
                <div className="logout_box">
                  <ProfilePic onLogout={onLogout} />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "34px" }}>
              <div className="col-md-3">
                <SettingSidebar />
              </div>
              <div className="col-md-9 mt-3">
                {isLoading ? (
                  <TableLoader rows={8} columns={6} />
                ) : (
                  <Paper
                    sx={{ height: "86vh", width: "100%", position: "relative" }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel,
                        },
                      }}
                      pageSizeOptions={[10, 20, 50, 100]}
                      paginationModel={paginationModel}
                      onPaginationModelChange={(newModel) => {
                        setPaginationModel(newModel);
                      }}
                      checkboxSelection
                      rowCount={total}
                      getRowId={(row) => row.id}
                      paginationMode="server"
                      disableRowSelectionOnClick
                      onRowSelectionModelChange={handleSelectionChange}
                      sx={{ border: 0 }}
                    />
                    {selectedItems?.length > 0 && (
                      <div className="selected_row_delete">
                        <button
                          className="btn btn_delete2"
                          onClick={handleDeleteSelected}
                        >
                          <MdDelete />
                        </button>
                      </div>
                    )}
                  </Paper>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrashedItems;
