import React, { useEffect, useState } from "react";
import AllCampaignTable from "../campaigns/AllCampaignTable";
import axios from "axios";
import { baseUrl } from "../../apiConfig";
import { toast } from "react-toastify";
import TableLoader from "../influencers/TableLoader";

const TopInfluencers = () => {
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const getCampaigns = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${baseUrl}/all-campaigns?campaignStatus=active`,
        { headers: { "auth-token": token } }
      );
      setAllCampaigns(res.data);
    } catch (error) {
      notifyError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const handleSelectionChange = (newSelection) => {
    setSelectedCampaign(newSelection);
  };

  const handleDeleteSelected = () => {
    handleDelete(selectedCampaign);
  };

  const notifySuccess = (message) =>
    toast.success(message || "Deleted successfully!");
  const notifyError = (error) => toast.error(error);

  const handleDelete = async (idsToDelete) => {
    try {
      await axios.delete(`${baseUrl}/campaign`, {
        data: { ids: idsToDelete },
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });
      notifySuccess("Deleted successfully!");

      setAllCampaigns((prev) =>
        prev?.filter((campaign) => !idsToDelete?.includes(campaign?._id))
      );
      setSelectedCampaign([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      notifyError("Error deleting files");
    }
  };

  return (
    <div className="top_infl mt-3">
      <div className="d-flex align-items-center justify-content-between">
        <h6>Active Campaigns</h6>
      </div>
      <div className="table_box">
        {isLoading ? (
          <TableLoader rows={4} columns={10} />
        ) : (
          <AllCampaignTable
            allCampaigns={allCampaigns}
            notifySuccess={notifySuccess}
            notifyError={notifyError}
            setAllCampaigns={setAllCampaigns}
            setSelectedCampaign={setSelectedCampaign}
            selectedCampaign={selectedCampaign}
            handleSelectionChange={handleSelectionChange}
            handleDeleteSelected={handleDeleteSelected}
            padding={"0"}
          />
        )}
      </div>
    </div>
  );
};

export default TopInfluencers;
